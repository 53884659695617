import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {Text, ThemeConsumer} from 'react-native-elements';
import {__} from '@ampeco/i18n';
import {format} from '@ampeco/currency';
import {Session} from '@ampeco/charge-models';
import SessionStatsRow from './SessionStatsRow';
import {ChargeTheme} from '@ampeco/theme';
import {PaymentMethod} from '@ampeco/models';

export class SessionSummaryMain extends React.Component<{session: Session, paymentMethod?: PaymentMethod}> {
    render() {
        return <ThemeConsumer>{({theme}) => {
            return <View style={[styles.container, theme.Container.byPaddingAndMarginTop]}>

            {(this.props.session.paymentDetails || this.props.session.paymentStatus === 'pending') &&
            <View>
                <SessionStatsRow
                    label={this.props.session.paymentStatus === 'pending' ? __('session.summary.total-pending') : __('session.summary.total-billed')}
                    left={true}
                >
                    {format(this.props.session.totalAmount, this.props.session.currency)}
                </SessionStatsRow>
                {this.props.session.taxName ? (
                    <Text style={[theme.SessionStatsRow.statTitle]}>
                        {__('session.summary.tax_included', {taxName : this.props.session.taxName })}
                    </Text>
                ) : null }
            </View>}
            {this.props.paymentMethod && (this.props.session.paymentDetails || this.props.session.paymentStatus === 'pending') &&
            <SessionStatsRow
                label={__('session.summary.payment-method')}
                left={false}
            >
                {this.props.paymentMethod.type !== 'corporate' &&
                <Image style={{width: 45, height: 28}} source={theme.SessionSummary.paymentImages[this.props.paymentMethod.type]}/>}
                <Text ellipsizeMode="tail" numberOfLines={1} style={[theme.SessionSummary.paymentMethodLabel, styles.paymentMethodLabel, {
                    paddingLeft: this.props.paymentMethod.type !== 'corporate' ? 17 : 0,
                }]}>{this.props.paymentMethod.name}</Text>
            </SessionStatsRow>
            }
            {!this.props.paymentMethod && this.props.session.paymentDetails && this.props.session.paymentDetails.type === 'voucher' &&
            <SessionStatsRow
                label={__('session.summary.payment-method')}
                left={false}
            >
                <Text ellipsizeMode="tail" numberOfLines={1}
                      style={[theme.SessionSummary.paymentMethodLabel, styles.paymentMethodLabel]}>{__('session.summary.balance')}</Text>
            </SessionStatsRow>
            }

        </View>;}}</ThemeConsumer>;
    }

}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    paymentMethodLabel: {
        flex: 1,
    },
});
